@font-face {
  font-family: "gilroy";
  src: local("gilroy"), url(./assets/fonts/Gilroy-Light.otf) format("opentype");
}

@font-face {
  font-family: "emberlyLight";
  src: local("emberlyLight"),
    url(./assets/fonts/Emberly-Light.otf) format("opentype");
}

@font-face {
  font-family: "emberly";
  src: local("emberly"),
    url(./assets/fonts/Emberly-Regular.otf) format("opentype");
}

@font-face {
  font-family: "moon";
  src: local("moon"), url(./assets/fonts/Moon2.0-Regular.otf) format("opentype");
}

@font-face {
  font-family: "moonLight";
  src: local("moonLight"),
    url(./assets/fonts/Moon2.0-Light.otf) format("opentype");
}

@font-face {
  font-family: "pommel";
  src: local("pommel"), url(./assets/fonts/Pommel\ Demo.otf) format("opentype");
}

/* Looks like it is between gilroy & moon so far */
/*  - https://digitalsynopsis.com/design/best-creative-free-download-fonts-designers/ */
/*  - https://visme.co/blog/pretty-fonts/ */

* {
  font-family: "gilroy";
}

/* https://www.npmjs.com/package/@kunukn/react-collapse */
.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
